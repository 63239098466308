<template>
  <div />
</template>

<script>
import { GetShiftsClass } from '@/api/shiftsClass'
import { UpdateShiftsSchedule } from '@/api/shiftSchedule'
import { GetServiceUnit } from '@/api/serviceUnit'
import { mapGetters } from 'vuex'
import dayjs from '@/lib/dayjs'

export default {
  name: 'shifCalendar',
  computed: {
    ...mapGetters(['shop']),
    unitQuery () {
      return this.$route.query.unit
    },
    shiftClassQuery () {
      return this.$route.query.shiftClass
    },
    dateList () {
      const query = this.$route.query.dateList
      if (!query) return null
      return JSON.parse(query)
    },
    options () {
      return this.$route.query.options
    },
  },
  async mounted () {
    const unit = await this.getServiceUnit()
    if (!unit) return
    let shiftClass
    if (this.options !== 'resetMonth') shiftClass = await this.getShiftClass(unit)

    const data = this.generateData(unit, shiftClass)
    await this.updateShiftsSchedule(data)
  },
  methods: {
    // => 取得當前店家所有班別總數
    async getShiftClass () {
      try {
        const res = await GetShiftsClass({
          shopId: this.shop,
          name: this.shiftClassQuery,
        })
        if (res.length) return res[0]
        return null
      } catch (error) {
        console.log(error)
        this.$message.error(error)
        return null
      }
    },

    async getServiceUnit () {
      try {
        const res = await GetServiceUnit({
          shopId: this.shop,
          name: this.unitQuery,
        })
        if (res.length) return res[0]
        return null
      } catch (error) {
        console.log(error)
        this.$message.error(error)
        return null
      }
    },

    async updateShiftsSchedule (data) {
      await UpdateShiftsSchedule({
        shopId: this.shop,
        data,
      })
    },

    generateData (unit, shiftClass) {
      const year = dayjs().year()
      let month = dayjs().month()
      if (month < 10) month = `0${month + 1}`
      else month = month + 1
      const days = dayjs().daysInMonth()

      const data = []

      for (let d = 1; d <= days; d++) {
        if (this.options !== 'resetMonth') {
          data.push({
            appointmentScheduleDayId: shiftClass.id,
            appointmentUnitId: unit.id,
            day: `${year}-${month}-${d}`,
          })
        }
        if (this.options === 'resetMonth') {
          data.push({
            appointmentUnitId: unit.id,
            day: `${year}-${month}-${d}`,
          })
        }
      }
      return data
    },
  },
}
</script>
